import React, { useMemo, useState } from "react";

import countryList from "react-select-country-list";
import Select from "react-select";

import "./kyc.css";

const statesByCountry = {
  US: [
    { label: "California", value: "CA" },
    { label: "New York", value: "NY" },
    { label: "Texas", value: "TX" },
  ],
  IN: [
    { label: "Maharashtra", value: "MH" },
    { label: "Uttar Pradesh", value: "DL" },
    { label: "Karnataka", value: "KA" },
  ],
};

const Step2 = ({ prevStep }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const options = useMemo(() => countryList().getData(), []);

  const changeCountryHandler = (value) => {
    setSelectedCountry(value);
    setSelectedState(null);
  };

  const changeStateHandler = (value) => {
    setSelectedState(value);
  };
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      // background: "black",
    }),
    option: (provided) => ({
      ...provided,
      background: "#1D1D23",
      color: "#757786",
      fontWeight: "400",
      fontSize: "16px",
      padding: "10px 20px",
      lineHeight: "16px",
      cursor: "pointer",
      borderBottom: "1px solid #141414",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    input: (provided) => ({
      ...provided,
      color: "#757786",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 12px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "4px",
      color: "red",
      height: "32px",
    }),
    svg: (provided) => ({
      ...provided,
      fill: "#757786 !important",
      ":hover": {
        fill: "#757786 !important",
      },
    }),
  };

  return (
    <div className="kycform">
      <div className="stepWrap">
        <span>Step 2/2</span>
      </div>
      <form className="kycFormWrap">
        <div className="secondCopyInput1">
          <span className="title">Number</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Enter your address number"
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
          </div>
        </div>
        <div className="secondCopyInput1">
          <span className="title">Street Address 1</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Enter your address number"
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
          </div>
        </div>

        <div className="secondCopyInput1">
          <span className="title">Street Address 2</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Enter your address number"
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
          </div>
        </div>

        <div className="secondCopyInput0">
          {/* <div className="secondCopyInput2">
            <span className="title">City</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="City"
              />
            </div> */}
          {/* </div> */}
          <div className="secondCopyInput2">
            <span className="title">City</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="City"
                //   value={webReceipt}
                //   onChange={(e) => setWebReceipt(e.target.value)}
              />
            </div>
          </div>
          <div className="secondCopyInput2">
            <span className="title">State</span>
            <div className="copyWrap copyWrapSec">
              <Select
                classNamePrefix="react-select"
                styles={customStyles}
                className="custom-dropdown"
                options={statesByCountry[selectedCountry?.value] || []}
                value={selectedState}
                onChange={changeStateHandler}
                placeholder="State"
              />
            </div>
          </div>
        </div>
        <div className="secondCopyInput0">
          <div className="secondCopyInput2">
            <span className="title">Country</span>
            <div className="copyWrap copyWrapSec">
              <Select
                classNamePrefix="react-select"
                styles={customStyles}
                className="custom-dropdown"
                options={options}
                value={selectedCountry}
                onChange={changeCountryHandler}
                placeholder="Country"
              />
            </div>
          </div>
          <div className="secondCopyInput2">
            <span className="title">Zip Code</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="Zip Code"
                //   value={webReceipt}
                //   onChange={(e) => setWebReceipt(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="flexBtnDiv">
          <div
            className="select-payment-method-button"
            style={{ width: "100%" }}
          >
            <button
              onClick={prevStep}
              //  disabled={loading} onClick={() => setStep(StepsEnum.Method)}
            >
              Back
            </button>
          </div>
          <div style={{ width: "100%" }}>
            <div className="buyNowBtnWrap">
              <button
                // disabled={disableButton}
                // onClick={handlePaymentSuccessClick}
                style={{ fontWeight: "500" }}
              >
                {/* <span style={{ fontWeight: "500px" }}>
              Pay</span>$
              {activeCard?.price} */}
                {/* {loading ? ( */}
                <div className="select-payment-method-button">
                  {/* <GlobalLoader /> */} Verify
                </div>
                {/* ) : (
                "Submit"
              )} */}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Step2;

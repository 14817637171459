/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";

import { Helmet } from "react-helmet";
import {
  Button,
  Grid,
  DialogContent,
  Menu,
  MenuItem,
  List,
  // TextField,
  ListItem,
  Box,
  LinearProgress,
} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
// import {
//   KeyboardArrowDown,
//   KeyboardArrowUp,
//   Person,
// } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import Modal from "../../hoc/modal/Modal";
import Login from "./Login";
import SignUp from "./SignUp";
import useStyles from "./Header.styles";
import session from "../../utils/session";
import {
  logoutStart,
  refreshLoginStart,
  refreshMetamaskLoginStart,
  resetSuccess,
} from "../../redux-saga/redux/auth/authentication";
import headerMenu from "./HeaderMenu.list.json";
import { getUserProfileDetailsStart } from "../../redux-saga/redux/profile/myProfile";
import Wallet from "./Wallet";
import KycVerification from "./KycVerification";
import OtpVerify from "./OtpVerify";
import SelfieModel from "./SelfieModel";
import { accountMenuInitialState, headerList } from "./intialState.js";
import MyProfile from "../User/MyProfile";
//import Transaction from "../User/Transaction";
import BetHistory from "../User/BetHistory";
import ResetPassword from "../User/ResetPassword";
import { toggleSlider } from "./toggle";
// import Deposit from "../User/Deposit";
// import SearchGames from "./../../container/Casino/SearchGames";
import SearchGamesMobile from "./../../container/Casino/SearchGames/searchMobile";
import {
  getPreviousChatsStart,
  getPreviousSupportChatsStart,
  getUnseenCountOfSupportChatStart,
} from "../../redux-saga/redux/chatbox";
import { toggleDepositWithdraw } from "../../redux-saga/redux/settings/themeSettings";
// import Referral from "./Referral/index.jsx";
import {
  addNewNotifications,
  getNotificationsStart,
  updateNotificationList,
  updateNotificationsCount,
} from "../../redux-saga/redux/notification/index.js";
import NotificationComponent from "./NotificationModel/notification.jsx";
import { socket } from "../../services/webSocket/supportChatSocket.js";
import CommentPopup from "./Comment/commentPopup.jsx";
import ExitPopup from "../pop-ups/ExitPopup.jsx";
import { getRecentsGamesStart } from "../../redux-saga/redux/casino/lobby/games.js";
import BuyCoins from "../User/Deposit/buyCoins/index.jsx";
import LoginNew from "./LoginNew/index.jsx";
import transactionsService from "../../services/transactions/index.js";
import { StepsEnum } from "../User/Deposit/buyCoins/constants.js";
import KyacVarificationNew from "./KycVarificationNew/index.jsx";

//import { getUserKycDetailsStart } from "../../redux-saga/redux/kycVerification/index.js";

let notificationToggleValue = false;

const Header = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes("/view-all")) {
      if (location.pathname.includes("game")) {
        return;
      }
      localStorage.removeItem("backGame");
      localStorage.removeItem("backGameValue");
    }
  }, [location]);
  const navigate = useNavigate();
  const [loginSignupView, setLoginSignupView] = useState("login");
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("ref");
  const offset = useRef(0);
  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openOtpVerify, setOtpVerify] = useState(false);
  const [openSelfieModel, setSelfieModel] = useState(false);
  const [openKycVerification, setKycVerification] = useState(false);
  const [openAccountMenu, setOpenAccountMenu] = useState(
    accountMenuInitialState
  );
  const [gameProgress, setGameProgress] = useState(0);
  const { userDetails } = useSelector((state) => state.myProfile);
  const { sidebarOpen } = useSelector((state) => state.themeSettings);
  const { darkMode, isDepositWithdrawOpen } = useSelector(
    (state) => state.themeSettings
  );
  // const [referralLink, setReferralLink] = useState(false);
  const classes = useStyles({ gameProgressWidth: gameProgress });
  const [openExitGame, setOpenExitGame] = useState(false);
  const {
    loading,
    metamaskLoading,
    isLoggedIn,
    metamaskConnected,
    success,
    signup,
    metamaskSuccess,
    loginFailure,
    isNeedHouseAddress,
    formSteps,
    selectedWallet,
  } = useSelector((state) => state.authentication);

  const { unseenNotifications } = useSelector((state) => state.notification);

  const [commentPopup, setCommentPopup] = useState(undefined);
  const [notificationToggle, setnotificationToggle] = useState(false);
  const [activeTab, setactiveTab] = useState("new");
  const [showAll, setShowAll] = useState(false);

  const [countdown, setCountdown] = useState(0);

  const handleClick = () => {
    // setCountdown(5);
    // setOpenExitGame(true);
    navigate(-1);
  };

  const handleNotificationToggle = () => {
    if (!userDetails?.user?.uuid) return;
    setnotificationToggle(true);
    dispatch(updateNotificationsCount());
  };

  const handleCloseNotificationToggle = () => {
    setnotificationToggle(false);
    socket.emit("updateAndFetchNotifications", {
      activeTab: "new",
      update: activeTab === "new",
      response: true,
    });
    setactiveTab("new");
  };

  const openAccount = Boolean(anchorElAccount);
  const openNotify = Boolean(notificationToggle);
  const handleOpenLogin = () => {
    setOpenLogin((prevState) => !prevState);
  };
  const handleDepositWithdrawOpen = () => {
    dispatch(toggleDepositWithdraw(!isDepositWithdrawOpen));
  };
  const handleOpenSignUp = () => {
    setOpenSignUp((prevState) => !prevState);
  };

  const handleOpenKycVerification = () => {
    setKycVerification((prevState) => !prevState);
  };

  const handleOpenOtpVerify = () => {
    setOtpVerify((prevState) => !prevState);
  };

  const handleOpenSelfie = () => {
    setSelfieModel((prevState) => !prevState);
  };

  const handleOpenAccount = (event) => {
    if (anchorElAccount) {
      return setAnchorElAccount(null);
    }
    setAnchorElAccount(event.currentTarget);
  };

  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };

  const handleCloseAll = () => {
    setOpenLogin(false);
    setOpenSignUp(false);
    setOtpVerify(false);
    setSelfieModel(false);
    setKycVerification(false);
  };
  const handleLogout = () => {
    setAnchorElAccount(null);
    dispatch(logoutStart());
  };

  const handleCloseComment = () => {
    setCommentPopup(undefined);
  };

  const handleOpenComment = (value) => {
    setCommentPopup(value);
  };

  useEffect(() => {
    const gameTotalPoint =
      userDetails?.currentLevel?.end_range -
      userDetails?.currentLevel?.start_range;

    if (gameTotalPoint === 0 || !gameTotalPoint) {
      setGameProgress(0);
    } else {
      const progress =
        ((userDetails?.points - userDetails?.currentLevel?.start_range) * 100) /
        gameTotalPoint;
      setGameProgress(progress);
    }
    if (!userDetails?.nextLevel) setGameProgress(100);
  }, [userDetails]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserProfileDetailsStart());
      dispatch(getPreviousChatsStart());
      dispatch(getPreviousSupportChatsStart());
      dispatch(getNotificationsStart());
      dispatch(getUnseenCountOfSupportChatStart());
      dispatch(getRecentsGamesStart({ coin: selectedWallet || "coin" }));
    }
    if (isLoggedIn && success) {
      setOpenLogin(false);
      dispatch(resetSuccess());
    }

    if (success && !isLoggedIn) {
      dispatch(resetSuccess());
    }
    if (signup && success) {
      setOpenSignUp(false);
      dispatch(resetSuccess());
    }
    if (loginFailure) {
      if (loginFailure.steps === 3) {
        setOpenLogin(false);
        setSelfieModel(false);
      }
      if (loginFailure.steps === 0) {
        setOpenLogin(false);
        setOtpVerify(true);
      }
      if (loginFailure.steps === 1) {
        setOpenLogin(false);
        setKycVerification(true);
      }
      if (loginFailure.steps === 2) {
        setOpenLogin(false);
        setSelfieModel(true);
      }
      if (loginFailure.steps === 4) {
        setOpenLogin(false);
        setKycVerification(true);
      }
      if (loginFailure.steps === 5) {
        setOpenLogin(false);
      }
    }
  }, [
    dispatch,
    isLoggedIn,
    success,
    signup,
    setOpenLogin,
    loading,
    metamaskLoading,
    metamaskConnected,
    t,
    navigate,
    metamaskSuccess,
    loginFailure,
    isNeedHouseAddress,
  ]);
  useEffect(() => {
    if (isNeedHouseAddress) {
      setOpenLogin(false);
      setKycVerification(true);
      if (openSelfieModel) {
        setKycVerification(false);
      }
    }
  }, [isNeedHouseAddress, openSelfieModel]);
  useEffect(() => {}, []);
  useEffect(() => {
    if (offset.current !== 1) {
      if (session.getSessionCred) {
        const user = session.getSessionCred;
        if (user.isMetaMaskUser) {
          dispatch(refreshMetamaskLoginStart());
        } else {
          dispatch(refreshLoginStart());
        }
      }
      offset.current = 1;
    }
  }, [dispatch]);

  const handleOpenAccountMenu = (accountMenuType) => {
    if (accountMenuType === "deposit" || accountMenuType === "withdraw") {
      handleDepositWithdrawOpen();
      return;
    }
    if (accountMenuType === "openTransactions") {
      navigate("/transaction-history");
      return;
    }
    if (accountMenuType === "openBonus") {
      navigate("/bonus-history");
      return;
    }
    if (accountMenuType === "openMyBets") {
      navigate("/bet-history");
      return;
    }
    setOpenAccountMenu((prevState) => ({
      ...prevState,
      [accountMenuType]: true,
    }));
  };

  const handleCloseAccountMenu = () => {
    setOpenAccountMenu(accountMenuInitialState);
  };

  const handleNavigate = (path) => {
    localStorage.removeItem("backGame");
    localStorage.removeItem("backGameValue");
    localStorage.removeItem("scrollPos");
    if (path === "/") return;
    navigate(path);
  };

  useEffect(() => {
    if (referralCode) {
      if (localStorage.getItem("useId")) {
        return navigate("/");
      }
      handleOpenSignUp();
    }
  }, [referralCode, navigate]);

  useEffect(() => {
    if (socket) {
      socket.on("updateNotiFicationData", async (data) => {
        dispatch(addNewNotifications({ data, notificationToggleValue }));
        dispatch(getUserProfileDetailsStart());
      });
      socket.on("updateBalance", async () => {
        dispatch(getUserProfileDetailsStart());
      });
      socket.on("getNotificationList", (data) => {
        dispatch(
          updateNotificationList({
            notificationListData: data,
            notificationToggleValue,
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, dispatch]);

  const userData = session?.getSessionCred;
  useEffect(() => {
    if (userData?.token) {
      socket.emit("userJoinEvent", userData?.token);
    }
  }, [userData?.token]);

  notificationToggleValue = notificationToggle;

  useEffect(() => {
    const body = document.body;
    if (
      isMobile &&
      (openAccountMenu.openChangePassword ||
        openAccountMenu.openMyBets ||
        openAccountMenu.openMyProfile ||
        openAccountMenu.openTransactions ||
        openLogin ||
        openSignUp)
    ) {
      body.style.position = "fixed";
      body.style.width = "100%";
      body.style.overflow = "hidden";
    } else {
      body.style.position = "";
      body.style.width = "";
      body.style.overflow = "";
    }
  }, [openLogin, isMobile, openAccountMenu, openSignUp]);
  useEffect(() => {
    if (Number(formSteps) === 3) {
      setSelfieModel(!openSelfieModel);
    }
  }, [formSteps]);
  const inGame = window.location.href.split("/")[3] === "game" ? true : false;
  const prevLocation = useRef(null);

  useEffect(() => {
    if (
      prevLocation?.current &&
      prevLocation?.current?.pathname?.includes("/game") &&
      !inGame
    ) {
      window.location.reload(true);
    }
    prevLocation.current = location;
  }, [location, inGame]);
  useEffect(() => {
    // Define the route where you want to reset the games state
    const homeRoute = "/";

    if (location?.pathname === homeRoute) {
      dispatch({ type: "RESET_GAMES_STATE" });
    }
  }, [location, dispatch]);
  const handleDo = () => {
    navigate(-1);
    //setOpenExitGame(false);
  };
  const [state] = useState(true);

  // show receipt
  const [step, setStep] = useState(StepsEnum.Amount);
  const [transactionStatus, setTransactionStatus] = useState(null);
  useEffect(() => {
    if (location.pathname === "/wallet") {
      (async () => {
        const { status, data } =
          await transactionsService.xCoinTransactionDetails({
            txnId: searchParams.get("transactionId"),
          });
        if (status && status === 200 && isLoggedIn) {
          dispatch(toggleDepositWithdraw(true));
          setStep(StepsEnum.Success);
          setTransactionStatus({
            id: data.id,
            createdAt: data.createdAt,
            status: data.success ? 200 : 400,
            payment: "XCoin",
            goldCoins: data.goldCoins,
            bonusCoins: data.bonusCoins,
            amount: data.amount,
          });
        }
      })();
    }
  }, [dispatch, isLoggedIn, location, searchParams]);

  return (
    <div className={`${classes.header} ${inGame ? "gameHeaderHide" : ""}`}>
      <Helmet>
        <html
          lang="en"
          className={`${isDepositWithdrawOpen ? "remove-scroll" : ""}`}
        />
      </Helmet>
      <Grid className={classes.headerActive}>
        <div className={`${classes.headerContent} fixedHeader`}>
          <Grid className={classes.headerLeftSection}>
            <Link
              to="/"
              className={`${classes.headerLogo} ${inGame ? "logo-none" : ""}`}
            >
              <span className={`${classes.logo} portrateLogo`}>
                <img src="/images/updateimg/logo-svg.svg" alt="logo" />
              </span>
              <span className={`${classes.mobLogo} mobilePortrateLogo`}>
                <img src="/images/updateimg/logo-svg.svg" alt="logo-mob" />
              </span>
            </Link>
            <div></div>
            <Link
              className={`${inGame ? "exit-game-link" : ""} exit-btn-none`}
              onClick={handleClick}
            >
              Exit Game
            </Link>
            <ListItem
              onClick={() => toggleSlider(dispatch, sidebarOpen)}
              className={`${classes.sidebarToggleIcon} ${
                inGame ? "logo-none" : ""
              } mobileLandscapeIcon`}
            >
              {/* {sidebarOpen ? (
                <>
                  <img
                    src="/images/toggle-icon.svg"
                    alt="toggle icon"
                    className="close-icon-shift"
                  />
                </>
              ) : (
                <img
                  className={`${classes.sidebarToggleIconClose} close-icon-shift`}
                  src="/images/toggle-icon.svg"
                  alt="toggle"
                />
              )} */}
            </ListItem>
            {/* )} */}
          </Grid>

          <Grid className={classes.navigationWrapper}>
            <Grid>
              {/* {!isMobile && <SearchGames />} */}
              {isMobile && <SearchGamesMobile />}
            </Grid>
            <List className={classes.navigation}>
              {headerList?.map((listItem, index) => (
                <ListItem
                  onClick={() => {
                    if (listItem.name === "All Games") {
                      handleNavigate(listItem?.to);
                    }
                  }}
                  key={index}
                >
                  <Link
                    className={
                      matchPath(
                        `/${listItem.to}`.replace(/ /g, "%20"),
                        location?.pathname || "zlehods"
                      ) &&
                      listItem.name !== "Leaderboard" &&
                      classes.listItemHighlight
                    }
                    to={listItem.name !== "Leaderboard" && "/referral-award"}
                    /* onClick={() => handleOpenReferral(listItem.name)} */
                  >
                    {listItem.name}
                    {listItem.name === "Leaderboard" ? (
                      //  /* ||
                      // listItem.name !== "Refer a Friend" */
                      <span className={classes.commingTag}>Coming Soon</span>
                    ) : null}{" "}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid className={classes.headerRight}>
            {!isLoggedIn && !loading ? (
              <Grid className={classes.headerLogin}>
                <>
                  <Button
                    className={classes.btnSecondary}
                    onClick={() => {
                      handleCloseAll();
                      setOpenLogin((prev) => !prev);
                      setLoginSignupView("login");
                    }}
                  >
                    {t("HEADER.LOGIN")}
                  </Button>
                  <Button
                    className={classes.btnPrimary}
                    onClick={() => {
                      handleCloseAll();
                      setOpenLogin((prev) => !prev);
                      setLoginSignupView("signup");
                    }}
                  >
                    {t("HEADER.SIGN_UP")}
                  </Button>
                </>
              </Grid>
            ) : null}

            {isLoggedIn ? (
              <Grid
                className={`${classes.headerAfterLogin} headerAftheLoginlandscape`}
              >
                <>
                  <Wallet userDetails={userDetails} />

                  <Grid className={`${classes.userSection} userSectionMobile`}>
                    <Box
                      className={`${classes.userNotify} userNotifyLandscape`}
                      onClick={handleNotificationToggle}
                    >
                      <Box className={classes.notifyIcon}>
                        {unseenNotifications > 0 ? (
                          <Box className={classes.notifyCount}>
                            <span>{unseenNotifications}</span>
                          </Box>
                        ) : null}
                        <img src="/images/Notification.svg" alt="notify icon" />
                      </Box>
                    </Box>
                    <Menu
                      id="account-menu-notify"
                      anchorEl={openNotify}
                      open={notificationToggle}
                      className={`${classes.accountMenu} ${
                        classes.notifyMenu
                      } ${!showAll ? classes.notifyHeight : ""}`}
                      onClose={handleCloseNotificationToggle}
                      MenuListProps={{
                        "aria-labelledby": "account-button",
                      }}
                    >
                      {darkMode ? (
                        <div className={classes.notifyShapeIcon}>
                          <img src="/images/top-icon.svg" alt="" />
                        </div>
                      ) : (
                        <div className={classes.notifyShapeIcon}>
                          <img src="/images/w-top-icon.svg" alt="" />
                        </div>
                      )}
                      <NotificationComponent
                        classes={classes}
                        handleOpenComment={handleOpenComment}
                        handleCloseNotificationToggle={
                          handleCloseNotificationToggle
                        }
                        activeTab={activeTab}
                        setactiveTab={setactiveTab}
                        setShowAll={setShowAll}
                        showAll={showAll}
                      />
                    </Menu>
                  </Grid>

                  <>
                    <Grid
                      onClick={handleOpenAccount}
                      className={classes.userSection}
                    >
                      <Grid className="arrowIcon arrowiconLandscape">
                        {anchorElAccount ? (
                          <img
                            style={{ transform: "rotate(-180deg)" }}
                            src="/images/up-arrrow.svg"
                            alt=""
                          />
                        ) : (
                          <img src="/images/up-arrrow.svg" alt="" />
                        )}
                      </Grid>

                      <Grid
                        className={`${classes.headerUserName} landscapeHeaderUserName`}
                      >
                        <p>{userDetails?.user?.email?.split("@")[0]}</p>
                        <Grid
                          className={`${classes.headerProgressBar} headerProgressBarLandscape`}
                        >
                          <LinearProgress
                            thickness={6}
                            value={gameProgress}
                            variant="determinate"
                            className={classes.headerLinearBar}
                          />
                        </Grid>
                      </Grid>

                      <Grid className={`${classes.userImage} userIconWallet`}>
                        <span className={classes.userBorder}>
                          <img
                            src={
                              userDetails?.user?.profile_image ||
                              "/images/user_icon.svg"
                            }
                            alt="player-icon"
                          />
                        </span>
                      </Grid>
                    </Grid>
                    <Menu
                      id="account-menu"
                      anchorEl={anchorElAccount}
                      open={openAccount}
                      className={`customProfilePopup ${classes.accountMenu}`}
                      onClose={handleCloseAccount}
                      MenuListProps={{
                        "aria-labelledby": "account-button",
                      }}
                    >
                      {darkMode ? (
                        <div className={classes.profileShapIcon}>
                          <img src="/images/top-icon.svg" alt="" />
                        </div>
                      ) : (
                        <div className={classes.profileShapIcon}>
                          <img src="/images/w-top-icon.svg" alt="" />
                        </div>
                      )}
                      <Box className={classes.modalUser} display="flex">
                        <Grid className={classes.userAvatar}>
                          <span className={`${classes.userBorder} userimg`}>
                            <img
                              src={
                                userDetails?.user?.profile_image ||
                                "/images/user_icon.svg"
                              }
                              alt="player-icon"
                            />
                          </span>
                          <span className={classes.userProfileLevel}>
                            {userDetails?.currentLevel?.id}
                          </span>
                        </Grid>

                        <Grid className={classes.userNameEmail}>
                          <p className={classes.userName}>
                            {userDetails?.user?.email?.split("@")[0] ||
                              "Rietanolieya"}
                          </p>
                          <div
                            className={`${classes.userEmail} userProfileEmail`}
                            title={
                              userDetails?.user?.email ||
                              "rietanolieya@gmail.com"
                            }
                          >
                            {userDetails?.user?.email ||
                              "rietanolieya@gmail.com"}
                          </div>
                        </Grid>
                      </Box>

                      <Box className={classes.progressBar}>
                        <LinearProgress
                          variant="determinate"
                          value={gameProgress}
                          className={classes.linearBar}
                        />
                        <Grid className={classes.grandStep}>
                          <p>
                            <span>Current:</span>
                            <span>{userDetails?.currentLevel?.userLevel}</span>
                          </p>

                          {userDetails?.nextLevel?.userLevel && (
                            <p>
                              <span>Next:</span>
                              <span>{userDetails?.nextLevel?.userLevel}</span>
                            </p>
                          )}
                        </Grid>
                      </Box>
                      {/* Removed this link from the header content */}
                      {/* // {
  //   "content": "COMMON.MY_PROFILE",
  //   "to": "/user/myProfile",
  //   "src": "/images/profile-icon.svg",
  //   "alt": "User Icon",
  //   "component": "MyProfile",
  //   "isOpen": "openMyProfile"
  // }, 
  // {
  //   "content": "HEADER.CHANGE_PASSWORD",
  //   "src": "/images/payment-icon/pwd.svg",
  //   "alt": "Lock Icon",
  //   "component": "ChangePassword",
  //   "isOpen": "openChangePassword"
  // }*/}
                      {headerMenu?.map((item) => (
                        <MenuItem
                          key={item?.component}
                          onClick={() => {
                            handleOpenAccountMenu(item?.isOpen);
                            handleCloseAccount();
                          }}
                          className=""
                        >
                          <Link className={`  ${classes.myAccountLink}`}>
                            <img src={item.src} alt={item.alt} />
                            {t(item?.content)}
                          </Link>
                        </MenuItem>
                      ))}
                      <MenuItem onClick={handleLogout}>
                        <span>
                          {" "}
                          <img
                            src="/images/logout.svg"
                            alt="logout Icon"
                          />{" "}
                        </span>
                        {t("HEADER.LOG_OUT")}
                      </MenuItem>
                    </Menu>
                  </>
                </>
              </Grid>
            ) : null}

            <Grid className="hamburgerIcon">
              <DehazeIcon />
            </Grid>
          </Grid>
        </div>
        <Modal
          isShowing={openLogin}
          content={
            state ? (
              <LoginNew
                setOpen={setOpenLogin}
                view={loginSignupView}
                setView={setLoginSignupView}
              />
            ) : (
              <DialogContent className={classes.loginWrap}>
                <Login
                  handleCloseLogin={handleOpenLogin}
                  handleOpenSignUp={handleOpenSignUp}
                />
              </DialogContent>
            )
          }
          hide={handleOpenLogin}
        />

        <Modal
          isShowing={openExitGame}
          content={
            <DialogContent className={classes.loginWrap}>
              <ExitPopup
                setOpenExitGame={setOpenExitGame}
                countdown={countdown}
                setCountdown={setCountdown}
                handleDo={handleDo}
                from="game"
              />
            </DialogContent>
          }
          hide={handleOpenLogin}
        />
        <Modal
          isShowing={openSignUp}
          content={
            <DialogContent className={classes.signupWrap}>
              <SignUp
                openOtpModel={handleOpenOtpVerify}
                handleOpenLogin={handleOpenLogin}
                handleCloseSignUp={handleOpenSignUp}
                referralCode={referralCode}
              />
            </DialogContent>
          }
          hide={handleOpenSignUp}
        />
        <Modal
          isShowing={openOtpVerify}
          content={
            <DialogContent className={classes.signupWrap}>
              <OtpVerify
                closeOtpModel={handleOpenOtpVerify}
                openKycModel={handleOpenKycVerification}
                openSelfieModel={handleOpenSelfie}
                referralCode={referralCode}
              />
            </DialogContent>
          }
          hide={handleOpenOtpVerify}
        />
        <Modal
          isShowing={openKycVerification}
          content={
            <DialogContent
              className={`${classes.kycVerification} ${classes.signupWrap}`}
            >
              <KycVerification
                closeKycModel={handleOpenKycVerification}
                openSelfieModel={handleOpenSelfie}
              />
            </DialogContent>
          }
          hide={handleOpenKycVerification}
        />
        <Modal
          isShowing={false}
          
          content={
            <DialogContent className={`${classes.scs} ${classes.abc}`}>
              <KyacVarificationNew
                closeKycModel={handleOpenKycVerification}
                openSelfieModel={handleOpenSelfie}
              />
            </DialogContent>
          }
          hide={handleOpenKycVerification}
        />
        <Modal
          isShowing={openSelfieModel}
          content={
            <DialogContent className={classes.signupWrap}>
              <SelfieModel
                closeSelfieModel={handleOpenSelfie}
                openSelfieModel={handleOpenSelfie}
              />
            </DialogContent>
          }
          hide={handleOpenSelfie}
        />
        <Modal
          isShowing={openAccountMenu?.openMyProfile}
          className={classes.profileWrap}
          content={
            <DialogContent className={classes.loginWrap}>
              <MyProfile
                userDetails={userDetails}
                gameProgress={gameProgress}
                handleCloseAccountMenu={handleCloseAccountMenu}
              />
            </DialogContent>
          }
          hide={handleCloseAccountMenu}
        />
        <Modal
          isShowing={openAccountMenu?.openMyBets}
          content={
            <DialogContent className={classes.loginWrap}>
              <BetHistory handleCloseAccountMenu={handleCloseAccountMenu} />
            </DialogContent>
          }
          hide={handleCloseAccountMenu}
        />
        <Modal
          isShowing={openAccountMenu?.openChangePassword}
          content={
            <DialogContent className={classes.loginWrap}>
              <ResetPassword handleCloseAccountMenu={handleCloseAccountMenu} />
            </DialogContent>
          }
          hide={handleCloseAccountMenu}
        />
        <div className="modalWalletMainWrap">
          <Modal
            isShowing={isDepositWithdrawOpen}
            buyCoins={"payment-popup"}
            className="walletModalDialog walletPopupOver modalWalletMainWrap"
            content={
              <DialogContent
                className={`mobileWrap ${classes.loginWrap} ${classes.walletModal} ${classes.walletPopup}`}
              >
                {/* <Deposit handleDepositWithdrawOpen={handleDepositWithdrawOpen} /> */}
                <BuyCoins
                  handleDepositWithdrawOpen={handleDepositWithdrawOpen}
                  transactionStatus={transactionStatus}
                  setTransactionStatus={setTransactionStatus}
                  step={step}
                  setStep={setStep}
                />
              </DialogContent>
            }
            hide={handleDepositWithdrawOpen}
          />
        </div>

        <Modal
          isShowing={commentPopup !== undefined}
          className="walletModalDialog"
          content={
            <DialogContent
              className={`${classes.loginWrap} ${classes.walletModal}`}
            >
              <CommentPopup
                handleCloseComment={handleCloseComment}
                commentPopup={commentPopup}
              />
            </DialogContent>
          }
          hide={handleCloseComment}
        />
      </Grid>
    </div>
  );
};

export default Header;

import React, { useState } from "react";

import { Box } from "@mui/material";

import Step1 from "./step1";
import Step2 from "./step2";

import "./kyc.css";

function KyacVarificationNew() {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <div className="kycVarificationMainWrap">
      <div className="sticyDiv">
        <div className="kycHeaderWrap loginHeadeWrap ">
          <p>Verification</p>
          <Box className="closeIcon">
            <img src="/images/loginCross.png" alt="" />
          </Box>
        </div>
        <div className="loginLogoWrap">
          <img src="/images/userIconLogo.png" className="kycLogoImg" alt="" />
          {/* <h1>Welcome to Fortunarush</h1> */}
          {step === 1 && <Step1 nextStep={nextStep} />}
          {step === 2 && <Step2 prevStep={prevStep} />}
        </div>
      </div>
    </div>
  );
}

export default KyacVarificationNew;

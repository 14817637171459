import React from "react";

import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import "./kyc.css";
const Step1 = ({ nextStep }) => {
  const {
    handleSubmit,
    // register,
    //formState: { errors },
    //setValue,
  } = useForm();

  //   const { billingUpdateSuccess, loading } = useSelector(
  //       (state) => state.authentication
  //     );
  //     console.log("UserDetails>>>", billingUpdateSuccess);
  //     const dispatch = useDispatch();

  //     const handleChange = (e, country) => {
  //       console.log("Country>>>>", country);
  //       setValue("phoneNumber", +e);
  //       setValue("countryCode", country?.countryCode);
  //       setValue("countryPhoneCode", country?.dialCode);
  //     };
  //     const handleCountryChange = (e, country) => {
  //       const { value } = e;
  //       const findCountry = countryList?.find((elem) => elem.name === value);
  //       if (findCountry) {
  //         getStatesOfCountry(findCountry?.master_country_id);
  //         setValue("country", value);
  //       }
  //     };
  //     const handleState = (e) => {
  //       const { value } = e;
  //       setValue("state", value);
  //     };
  //     useEffect(() => {
  //       if (userDetails?.user?.country) {
  //         setValue("country", userDetails.user?.country);
  //       }
  //       if (userDetails?.user?.state) {
  //         setValue("state", userDetails.user?.state);
  //       }
  //       if (userDetails?.user?.city) {
  //         setValue("city", userDetails?.user?.city);
  //       }
  //       if (userDetails?.phoneNumber) {
  //         setValue("phoneNumber", userDetails?.user?.phone_number);
  //         setValue("countryCode", userDetails?.user?.country_code);
  //         setValue("countryPhoneCode", userDetails?.user?.country_phone_code);
  //       }
  //     }, [userDetails]);

  const registerBillingAddress = (values) => {
    try {
      // dispatch(billingAddressStart(values));
    } catch (err) {
      toast.error("Something went wrong!", { toastId: "123" });
    }
  };
  return (
    <div className="kycform">
      <div className="stepWrap">
        <span>Step 1/2</span>
      </div>
      <form
        className="kycFormWrap"
        onSubmit={handleSubmit(registerBillingAddress)}
      >
        <div className="secondCopyInput1 first-input  ">
          <span className="title">First Name</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Enter your first name"
              // {...register("addressNumber")}
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
          </div>
        </div>
        <div className="secondCopyInput1">
          <span className="title">Last Name</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Enter your last name"
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
          </div>
        </div>
        <div className="secondCopyInput1">
          <span className="title">Date of birth</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="mm.dd.yy"
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
          </div>
        </div>
        <div className="secondCopyInput1">
          <span className="title">Phone number</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Enter your phone number"
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
          </div>
        </div>
      </form>

      <div className="buyNowBtnWrap cardWrapScreen">
        <button onClick={nextStep}>Next step</button>
      </div>
    </div>
  );
};

export default Step1;
